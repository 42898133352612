<template>
  <div>
    <b-card >
      <b-row align-h="end">
        <b-col cols="2" class="my-1" align-self="end" style="text-align: end">
          <div
            class="d-flex align-items-center justify-content-end"
            align-self="end"
            style="text-align: end"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                class="d-inline-block"
                placeholder="Search"
              />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>

            <!-- <v-select v-model="filter" :options="statusOptions" class="invoice-filter-select" placeholder="Select Status">
                    <template #selected-option="{ label }">
                        <span class="text-truncate overflow-hidden">
                            {{ label }}
                        </span>
                    </template>
                </v-select> -->
          </div>
        </b-col>
      </b-row>
      <div>
        <b-table
          :busy="isBusy"
          responsive="sm"
          :fields="headers"
          :per-page="perPage"
          :items="elements"
          :current-page="currentPage"
          :filter="filter"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
              <strong> Loading...</strong>
            </div>
          </template>
          <template #cell(total_price)="data">
            {{
              "$" + Number(data.value).toLocaleString("en", { minimumFractionDigits: 2 })
            }}
          </template>
          <template #cell(unit_delivery_w_install)="data">
            <div style="min-width: 95%">
              {{
                "$" +
                Number(data.value).toLocaleString("en", { minimumFractionDigits: 2 })
              }}
            </div>
          </template>
          <template #cell(supply_price)="data">
            <div style="min-width: 95%">
              {{
                "$" +
                Number(data.value).toLocaleString("en", { minimumFractionDigits: 2 })
              }}
            </div>
          </template>
          <template #cell(unit_cost)="data">
            <div style="min-width: 95%">
              {{
                "$" +
                Number(data.value).toLocaleString("en", { minimumFractionDigits: 2 })
              }}
            </div>
          </template>
        </b-table>
        <b-row style="float: right">
          <b-col cols="2">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCol,
  BRow,
  BPagination,
  BCard,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
export default {
  components: {
    BTable,
    BCol,
    BRow,
    BPagination,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      headers: [
        {
          key: "uid",
          label: "UID",
          sortable: true,
        },
        {
          key: "element_name",
          label: "NAME",
          sortable: true,
        },
        {
          key: "spec_id",
          label: "SPEC ID",
          sortable: true,
        },
        {
          key: "specification_key",
          label: "SPECIFICATION KEY",
          sortable: true,
        },
        {
          key: "category",
          label: "CATEGORY",
          sortable: true,
        },
        {
          key: "unit_cost",
          label: "COGS / Unit",
          sortable: true,
        },
        {
          key: "supply_price",
          label: "Price / Unit",
          sortable: true,
        },
        {
          key: "unit_delivery_w_install",
          label: "UNIT DELIVERY WITH INSTALL",
          sortable: true,
        },
        {
          key: "total_price",
          label: "Total Price (w/ D&I)",
          sortable: true,
        },
      ],
      elements: [],
      filter: null,
      isBusy: false,
      perPage: 15,
      currentPage: 1,
      totalRows: 1,
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {
    this.getElements();
  },
  methods: {
    getElements() {
      let vm = this;
      vm.isBusy = true;
      this.$http
        .get(`/elements`)
        .then((response) => {
          let { data } = response;
          vm.elements = data;
          vm.totalRows = vm.elements.length;
        })
        .catch((error) => {
          this.showToast("danger", "Unauthorized");
          this.$router.push({
            path: "/",
          });
        });
      vm.isBusy = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
